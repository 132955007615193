$tileWidth: 60px;
$tileHeight: $tileWidth;
$tileBackgroundColor: #cccccc;

.tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 175%;
	color: rgba($color: #000000, $alpha: 0.7);
	width: $tileWidth;
	height: $tileHeight;
	background-color: $tileBackgroundColor;
	text-shadow: 0 0 25px gray;
}
