.base {
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.mainTitle {
	margin-top: 4rem;
}

.movesNumber {
	text-align: center;
	margin-top: 1rem;
}

.resetButton {
	padding-block: 0.3rem;
	padding-inline: 2rem;
	margin-block: 1rem;
	border: 1px solid gray;
	border-radius: 3px;
}
